.titan-modal {
  &.common {
    width: 502px;
    padding: 40px 24px;
    background-color: color(background-primary);
    border-radius: 8px;

    @include respond-down(sm) {
      height: 100%;
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0px 20px 0px;

    img {
      width: auto;
      height: 30px;
      object-fit: cover;
    }
  }

  &__title {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: color(text-grey-200);
  }
  &__note {
    padding: 10px 12px 10px 12px;
    border-radius: 8px;
    gap: 12px;
    display: flex;
    align-items: center;
    color: color(warning-500);
    border: 1px solid color(warning-500);
    background-color: color(text-grey-800);
  }

  .ant-modal-body {
    background-color: color(background-primary);
    border-radius: 8px;
    padding: 40px 24px;

    @include respond-down(sm) {
      padding: 16px;
    }
  }

  .ant-modal-content {
    background-color: transparent;
  }

  &__content {
    &.center {
      text-align: center;
    }

    .box-icon {
      margin-bottom: 32px;

      .icon {
        font-size: 60px;

        &.success {
          color: color(green-primary);
        }

        &.error {
          color: color(error-primary);
        }

        &.large {
          font-size: 45px;
          height: 45px;
          width: 45px;
        }
      }
    }

    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: white;
      margin-bottom: 8px;

      &.center {
        text-align: center;
      }
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: color(text-default);
    }
  }

  .create_category {
    display: flex;
    margin: 32px auto 0 auto;
    width: 70%;
    &__submit {
      width: 100%;
      height: 44px;
      min-width: 160px;
    }
    .category {
      margin-right: 12px;
      color: color(primary);
      border-color: color(primary);
    }
  }

  &__footer {
    .actions {
      margin-top: 32px;

      @include respond-down(sm) {
        width: 100%;
      }

      &.center {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.end {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .submit {
        height: 44px;
        padding-left: 40px;
        padding-right: 40px;
        font-size: 16px;
        font-weight: 600;
        // color: color(text-color);

        @include respond-down(sm) {
          padding-left: 16px;
          padding-right: 16px;
          width: 100%;
        }

        &.danger {
          background-color: color(error-primary);
          color: white;
          border: 1px solid color(error-primary);
        }
      }

      .cancel {
        height: 44px;
        padding-left: 40px;
        padding-right: 40px;
        font-size: 16px;
        font-weight: 600;
        color: color(primary);
        margin-right: 16px;

        @include respond-down(sm) {
          padding-left: 24px;
          padding-right: 24px;
          width: 100%;
        }
      }
      .warn {
        height: 44px;
        padding-left: 40px;
        padding-right: 40px;
        font-size: 16px;
        font-weight: 600;
        color: color(error-primary);
        border: 1px solid color(error-primary);
        margin-right: 16px;

        @include respond-down(sm) {
          padding-left: 24px;
          padding-right: 24px;
          width: 100%;
        }
      }
      .cancel-delete {
        height: 44px;
        padding-left: 40px;
        padding-right: 40px;
        font-size: 16px;
        font-weight: 600;
        color: color(error-primary);
        border: 1px solid color(error-primary);
        margin-right: 16px;

        @include respond-down(sm) {
          padding-left: 24px;
          padding-right: 24px;
          width: 100%;
        }
      }
    }
  }

  &.term {
    width: 80vw !important;

    @include respond-down(sm) {
      width: 100vw !important;
      max-width: 100vw;
      vertical-align: bottom;
      margin-bottom: 0;
    }

    .description {
      max-height: calc(100vh - 250px);
      overflow-y: auto;
      @include customScrollTrack(8px, color(background-secondary), 4px);

      @include respond-down(sm) {
        max-height: calc(80vh - 100px);
      }
    }

    .ant-modal-body {
      @include respond-down(sm) {
        padding: 16px;
        padding-bottom: 34px;
      }
    }
  }

  &__wrap-600 {
    .ant-modal {
      width: 600px !important;

      @include respond-down(sm) {
        width: 100vw !important;
      }
    }
  }

  &__wrap-500 {
    .ant-modal {
      width: 500px !important;

      @include respond-down(sm) {
        width: 100vw !important;
      }
    }
  }

  &--change-pw {
    .authenticate__form {
      width: auto;
      padding: 0;
    }
  }

  &--form-mobile {
    @include respond-down(sm) {
      max-width: 100vw !important;
      width: 100vw !important;
      vertical-align: bottom !important;
      margin-bottom: 0;
    }

    .ant-modal-body {
      @include respond-down(sm) {
        border-radius: 0;
        padding: 16px 16px 32px 16px;
      }
    }

    .actions {
      @include respond-down(sm) {
        .ant-form-item-row {
          width: 100%;

          .ant-form-item-control-input-content {
            width: 100%;
            display: flex;
            align-items: center;

            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.33);
  backdrop-filter: blur(20px);

  @include respond-down(sm) {
    backdrop-filter: blur(6px);
  }
}

.ant-modal {
  @include respond-down(sm) {
    max-width: calc(100vw - 32px);
  }
}

.dialog-error {
  z-index: 10000 !important;
}
