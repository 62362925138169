.titan-page {
  &--bot {
    .list-combo {
      margin-bottom: 24px;
      &__detail {
        display: flex;
        flex-wrap: wrap;
        margin-left: -12px;
        margin-right: -12px;
        @include respond-down(md) {
          flex-wrap: nowrap;
          overflow-x: auto;
          @include customScrollTrack(6px, color(background-secondary), 6px);
        }
        @include respond-down(sm) {
          flex-wrap: nowrap;
          overflow-x: auto;
          @include customScrollTrack(6px, color(background-secondary), 6px);
        }
        .combo-item {
          width: calc(100% / 3);
          padding-left: 12px;
          padding-right: 12px;
          border-radius: 8px;
          @include respond-down(md) {
            width: 400px;
          }
          @include respond-down(sm) {
            width: calc(100vw - 56px);
          }
          .img {
            height: 220px;
            width: 100%;
            object-fit: cover;
            border-radius: 8px 8px 0 0;
            @include respond-down(md) {
              width: 370px;
            }
            @include respond-down(sm) {
              width: calc(100vw - 80px);
            }
          }
          &__info {
            padding: 24px;
            background-color: color(background-primary);
            border-radius: 0 0 8px 8px;
            .top {
              display: flex;
              align-items: flex-start;
              @include respond-down(sm) {
                flex-wrap: wrap;
              }
              .name {
                color: color(text-grey);
                font-size: 16px;
                font-weight: 500;
              }
              .discount {
                padding: 2px 8px;
                margin-left: auto;
                background-color: color(error-primary);
                color: white;
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                border-radius: 4px;
              }
            }
            .description {
              margin-top: 16px;
              margin-bottom: 24px;
              font-size: 24px;
              font-weight: 700;
              line-height: 36px;
              color: white;
            }
            .btn {
              width: 100%;
            }
          }
        }
      }
    }
    .list-bot {
      .bot-item {
        margin-top: 24px;
        padding: 24px;
        background-color: color(background-primary);
        border-radius: 8px;
        display: flex;
        margin-left: -12px;
        margin-right: -12px;
        @include respond-down(md) {
          flex-direction: column;
          padding: 16px;
        }
        @include respond-down(sm) {
          flex-direction: column;
          padding: 16px;
          margin-left: 0;
          margin-right: 0;
        }
        .left {
          width: 40%;
          padding-right: 12px;
          @include respond-down(md) {
            width: 100%;
            padding-right: 0;
          }
          @include respond-down(sm) {
            width: 100%;
            padding-right: 0;
          }
          .top {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            @include respond-down(sm) {
              flex-wrap: wrap;
              margin-bottom: 16px;
            }
            .info-left {
              display: flex;
              align-items: center;
              // width: 100%;
              // flex: 1;
              @include respond-down(sm) {
                flex-wrap: wrap;
                width: 100%;
              }
              .info-title {
                display: flex;
                align-items: center;
                @include respond-down(sm) {
                  width: 100%;
                }
                .img-bot {
                  width: 48px;
                  height: 48px;
                  border-radius: 50%;
                  object-fit: cover;
                  margin-right: 12px;
                }
                .name {
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 28px;
                  color: white;
                }
              }
            }
            .info-right {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              flex: 1;
              @include respond-down(sm) {
                margin-top: 16px;
                flex-direction: row-reverse;
              }
              .exchange {
                display: flex;
                align-items: center;
                margin-left: 16px;
                white-space: nowrap;
                @include respond-down(sm) {
                  white-space: nowrap;
                }
                img {
                  margin-right: 8px;
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  padding: 4px;
                  background-color: color(background-secondary-bold);
                }
                &__name {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  color: #ffffff;
                }
              }
              .user {
                margin-left: auto;
                padding: 4px 8px;
                border-radius: 1000px;
                border: 1px solid #1bbc8e;
                color: #1bbc8e;
                white-space: nowrap;
                margin-left: 8px;
                @include respond-down(sm) {
                  white-space: nowrap;
                  margin-left: 0;
                }
                &.full {
                  border: 1px solid #ff392c;
                  color: #ff392c;
                }
              }
            }
          }
          .list-info {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-left: -6px;
            margin-right: -6px;
            margin-top: 24px;
            @include respond-down(sm) {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              align-items: initial;
              grid-auto-rows: 1fr;
              grid-row-gap: 12px;
            }
            .info-item {
              width: calc(100% / 2);
              padding-left: 6px;
              padding-right: 6px;
              margin-bottom: 12px;
              @include respond-down(sm) {
                width: 100%;
                height: 100%;
              }
              &__detail {
                padding: 8px 12px;
                background-color: color(background-third);
                border-radius: 8px;
                @include respond-down(sm) {
                  height: 100%;
                }
                .title {
                  font-size: 14px;
                  color: color(text-grey);
                  font-weight: 500;
                  line-height: 20px;
                  margin-bottom: 4px;
                }
                .value {
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 26px;
                  color: color(text-default);
                  &.success {
                    color: color(green-primary);
                  }
                  &.error {
                    color: color(error-primary);
                  }
                }
              }
              &__tooltip {
                margin-left: 4px;
              }
            }
          }
        }
        .right {
          width: 60%;
          padding-left: 12px;
          @include respond-down(md) {
            width: 100%;
            padding-left: 0;
          }
          @include respond-down(sm) {
            width: 100%;
            padding-left: 0;
          }
          .actions {
            display: flex;
            justify-content: center;
            align-items: center;
            @include respond-down(sm) {
              margin-left: -8px;
              margin-right: -8px;
            }
            .btn {
              margin-left: 8px;
              margin-right: 8px;
              @include respond-down(sm) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
