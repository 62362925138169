.titan-page {
  .filter-selected-items {
    display: flex;
    align-items: center;
    margin: 0px 10px;
    gap: 6px;
    &__label {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: color(text-grey-400);
    }
    &__option {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: right;
      color: color(text-grey-25);
    }
  }
  .card-item {
    height: 100%;
    padding: 16px !important;
    border-radius: 12px;
    border: 1px;
    gap: 20px;
    background: #1d2939;
    border: 1px solid #344054;
    &__title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #98a2b3;
    }

    &__total-text {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      color: #fcfcfd;
      margin: 10px 0px;
    }
    &__chart {
      margin-top: 16px;
      border-radius: 8px;
      background: color(text-grey-900);
    }
    &__quick-filter {
      display: flex;
      justify-content: end;

      gap: 10px;
      &-active {
        padding: 4px 8px 4px 8px;
        border-radius: 8px;
        gap: 10px;
        background: color(text-grey-900);
        cursor: pointer;
      }
      &-inactive {
        padding: 4px 8px 4px 8px;
        border-radius: 8px;
        gap: 10px;
        border: 1px solid color(text-grey-700);
        color: color(text-grey-600);
        cursor: pointer;
      }
    }
    .item-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      .item-text {
        display: flex;
        justify-content: center;
        gap: 8px;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: color(text-grey-400);
      }
      .item-amount {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: color(text-grey-25);
      }
      .item-color {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        margin-right: 10px;
      }
    }
  }
}
