.titan-page {
  &--profile {
    .box-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
      img {
        width: 160px;
        height: 160px;
        border-radius: 50%;
      }
    }
    .titan-page__content {
      padding: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .info {
        &__item {
          list-style-type: none;
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          label {
            font-size: 16px;
            font-weight: 500;
            color: color(text-default);
            width: 150px;
          }
          .value {
            font-size: 16px;
            font-weight: 400;
            color: color(text-default);
          }
        }
      }
    }
  }
}
