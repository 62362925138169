.referred-user-list-page {
  .rul-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    &__item {
      display: flex;
      min-height: 162px;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 16px;
      border-radius: 12px;
      border: 1px;
      background: linear-gradient(0deg, #344054, #344054), linear-gradient(0deg, #1d2939, #1d2939);
      &__total {
        display: flex;
        flex-direction: column;
        .text-total {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: color(text-grey-400);
        }
        .text-amount {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
          color: color(text-grey-25);
        }
      }
      &__list {
        width: 100%;
        .item-wrapper {
          display: flex;
          justify-content: space-between;
          margin: 5px 0px;
          .item-color {
            width: 12px;
            height: 12px;
            border-radius: 6px;
            margin-right: 10px;
          }
          .item-text {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: color(text-grey-400);
          }
          .item-amount {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: color(text-grey-25);
          }
        }
      }
    }
  }
}
