.titan-alert {
  &--error {
    margin-bottom: 24px;
    border: 1px solid color(error-primary);
    background-color: #ffe3e0;
    .ant-alert-message {
      color: color(error-primary);
    }
  }
}

.titan-text {
  &.success {
    color: color(green-primary);
  }
  &.error {
    color: color(error-primary);
  }
}

.titan-chip {
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 100px;
  width: fit-content;
  white-space: nowrap;
  background-color: color(background-grey);
  color: color(text-color);
  &.success {
    color: color(green-primary);
    background-color: #d0ffdd;
  }
  &.success-secondary {
    color: #1bbc8e;
  }
  &.error {
    color: color(error-primary);
    background-color: #ffe3e0;
  }
  &.outlined {
    border: 1px solid color(background-grey);
    &.pending {
      color: color(primary);
      background-color: transparent;
      border: 1px solid color(primary);
    }
    &.success {
      color: color(green-primary);
      background-color: transparent;
      border: 1px solid color(green-primary);
    }
    &.success-secondary {
      color: #1bbc8e;
      border: 1px solid #1bbc8e;
      background-color: transparent;
    }
    &.error {
      color: color(error-primary);
      background-color: transparent;
      border: 1px solid color(error-primary);
    }
  }
}

.pl-16 {
  padding-left: 16px;
}
.text-link {
  &:hover {
    cursor: pointer;
  }
}
