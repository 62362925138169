.commission-page {
  &__report {
    display: flex;
    .item-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      .item-color {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        margin-right: 10px;
      }
      .item-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: color(text-grey-400);
      }
      .item-amount {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: color(text-grey-25);
      }
      .item-percent {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: right;
        color: #98a2b3;
      }
    }
    .card-commission {
      height: 100%;
      padding: 16px !important;
      border-radius: 12px;
      border: 1px;
      gap: 20px;
      background: #1d2939;
      border: 1px solid #344054;
      &__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #98a2b3;
      }
      &__total-text {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #fcfcfd;
        margin: 10px 0px;
      }
    }
  }
}
