.titan-page {
  .google-authenticator {
    &__content {
      padding: 48px;
      @include respond-down(sm) {
        padding: 24px 16px;
      }
      .title {
        margin-bottom: 48px;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: white;
        @include respond-down(sm) {
          margin-bottom: 24px;
        }
      }
      &__detail {
        padding-top: 48px;
        padding-bottom: 48px;
        border-top: 1px solid color(border-primary);
        border-bottom: 1px solid color(border-primary);
        @include respond-down(sm) {
          padding-top: 24px;
          padding-bottom: 24px;
        }
      }
      .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 48px;
        @include respond-down(sm) {
          margin-top: 24px;
          margin-left: -12px;
          margin-right: -12px;
        }
        button {
          margin-left: 12px;
          margin-right: 12px;
          @include respond-down(sm) {
            width: 100%;
            margin: 0 12px;
          }
        }
      }
      .content-detail {
        .top {
          margin-top: 48px;
          margin-bottom: 48px;
          text-align: center;
          @include respond-down(sm) {
            margin-top: 24px;
            margin-bottom: 24px;
          }
          .title-step {
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: white;
            margin-bottom: 8px;
          }
          .description {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: color(text-grey);
          }
        }
        .block-detail {
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 600px;
          @include respond-down(md) {
            width: 100%;
          }
          @include respond-down(sm) {
            width: 100%;
          }
          &.download-app {
            @include respond-down(sm) {
              width: 100%;
            }
            .list-app {
              display: flex;
              align-items: center;
              justify-content: center;
              @include respond-down(sm) {
                flex-direction: column;
                justify-content: center;
                width: 100%;
              }
              a {
                margin-left: 12px;
                margin-right: 12px;
                @include respond-down(sm) {
                  margin: 0 0 16px 0;
                  width: 100%;
                  text-align: center;
                  img {
                    height: 70px;
                  }
                }
              }
            }
          }
          &.qr-code-app {
            @include respond-down(sm) {
              display: flex;
              flex-direction: column;
              margin-bottom: 12px;
            }
            .box-img {
              margin-right: 24px;
              @include respond-down(sm) {
                margin-right: 0;
              }
            }
            .qr-code {
              margin-right: 24px;
              @include respond-down(sm) {
                margin-right: 0;
              }
            }
            .info {
              @include respond-down(sm) {
                margin-top: 16px;
              }
              .description {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: white;
                margin-bottom: 8px;
              }
            }
          }

          .code {
            font-weight: 400;
            font-size: 16px;
            background-color: color(background-third);
            color: white;
            padding: 8px 12px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
.otp-list-authenticator {
  display: flex;
  flex-direction: column;
  .titan-form--otp {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 8px;
    }
    .input-otp-wrap {
      width: 350px;
      margin: 16px auto;
      @include respond-down(sm) {
        width: 100%;
      }
    }
    .actions {
      margin-top: 0;
    }
  }
}
