.titan-page {
  &--help_center {
    &__panels {
      width: 100%;

      .tab-bar {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin: 8px 0 24px 0;

        .titan-input--search {
          max-width: 240px !important;
        }

        .create_category {
          display: flex;
        }

        &__submit {
          width: 100%;
          height: 44px;
          min-width: 160px;
          color: #2e260a;
        }

        .category {
          margin-right: 12px;
          color: color(primary);
          border-color: color(primary);
        }
      }
    }

    .row-filter {
      .create_category {
        display: flex;
        justify-content: flex-end;

        &__submit {
          width: 100%;
          height: 44px;
          max-width: 160px;
          color: #2e260a;
          
        }

        .category {
          margin-right: 12px;
          color: color(primary);
          border-color: color(primary);
        }
      }
    }
  }
  .help-center {
    &__items {
      margin-top: 16px;
      border: 1px solid color(border-primary);
      border-radius: 16px;
      &__header {
        padding: 18px;
        background: #21201e;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
      .sub-category2 {
        padding: 10px 12px;
        width: max-content;
        min-width: 344px;
        gap: 8px;
        height: 44px;
        background: #4d4d4d;
        border-radius: 8px;
        margin-top: 4px;
      }
      .icon {
        margin-left: 26px;
        cursor: pointer;
      }
      &__content {
        padding: 18px;
      }
    }
  }
}
.edit-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffc900;
  margin-right: 12px;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: black !important;
  }
}
.delete-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
.container-nodata {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;
}
