/*
 * CKEditor 5 (v38.0.1) content styles.
 * Generated on Wed, 14 Jun 2023 08:26:36 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */

:root {
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-todo-list-checkmark-size: 16px;
}
.ck-balloon-panel {
  background-color: #21201e !important;
}
.ck-toolbar {
  border: none !important;
  background-color: #21201e !important;
}
.ck-button {
  color: white !important;
}
.__separator {
  display: none !important;
}
.ck-content {
  height: 400px !important;
  background-color: color(background-secondary) !important;
  border: 1px solid color(border-primary) !important;
  color: white;
  &:focus {
    border: 1px solid color(primary) !important;
  }
}
