.titan-page {
  &--api-connection {
    .exchange {
      color: color(text-grey);
      font-size: 18px;
      font-weight: 400;
      span {
        font-weight: 700;
        color: white;
      }
    }
    .api-connection {
      &__not-connect-content {
        .top {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .title {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: white;
          }
          .description {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: color(text-default);
          }
        }
        .list-connect {
          margin-top: 48px;
          display: flex;
          @include respond-down(md) {
            grid-template-columns: repeat(2, 1fr);
            align-items: initial;
            grid-auto-rows: 1fr;
            grid-column-gap: 12px;
            width: 100%;
          }
          @include respond-down(sm) {
            grid-template-columns: repeat(2, 1fr);
            align-items: initial;
            grid-auto-rows: 1fr;
            grid-column-gap: 12px;
            width: 100%;
          }
          .connect-item {
            padding: 34px 16px;
            background-color: color(primary);
            border-radius: 8px;
            margin-left: 12px;
            margin-right: 12px;
            width: 260px;
            @include respond-down(md) {
              width: 100%;
            }
            @include respond-down(sm) {
              width: 100%;
              margin-left: 0;
              margin-right: 0;
            }
            .value {
              color: color(text-color);
              font-weight: 700;
              font-size: 24px;
              line-height: 36px;
              text-align: center;
            }
            .description {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: color(text-color);
              text-align: center;
            }
          }
        }
      }
      &__content-more-value {
        .content-detail {
          margin-top: 24px;
        }
        .content-step {
          .title {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: white;
            text-align: center;
          }
          .box-description {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
              margin-top: 4px;
              text-align: center;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: color(text-default);
            }
            a {
              margin-top: 4px;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
            }
            .box-img {
              margin-top: 24px;
              text-align: center;
              display: flex;
              flex-direction: column;
              &.img-row {
                flex-direction: row;
                @include respond-down(md) {
                  flex-direction: column;
                }
                @include respond-down(sm) {
                  flex-direction: column;
                }
                img {
                  margin-left: 16px;
                  margin-right: 6px;
                  margin-top: 0;
                  border-radius: 8px;
                  @include respond-down(md) {
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 16px;
                  }
                  @include respond-down(sm) {
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 16px;
                  }
                }
              }
              & img {
                margin-top: 16px;
                border-radius: 8px;
                @include respond-down(md) {
                  width: 100%;
                  height: auto;
                  object-fit: cover;
                }
                @include respond-down(sm) {
                  width: 100%;
                  height: auto;
                  object-fit: cover;
                }
                &:first-child {
                  margin-top: 0;
                }
              }
              & + p {
                margin-top: 24px;
              }
            }
          }
        }
        .actions {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 24px;
          @include respond-down(sm) {
            width: 100%;
          }
          button {
            margin-left: 8px;
            margin-right: 8px;
            @include respond-down(sm) {
              width: 100%;
              &:first-child {
                margin-right: 8px;
                margin-left: 0;
              }
              &:last-child {
                margin-left: 8px;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
    .block-api-connected {
      margin-top: 24px;
      .block-item {
        padding: 8px 12px;
        border-radius: 8px;
        background-color: color(background-third);
        &:not(:last-child) {
          margin-bottom: 24px;
        }
        .form-label {
          font-weight: 400 !important;
        }
        .value {
          font-size: 16px;
          font-weight: 700;
          color: white;
          line-height: 24px;
          margin-top: 4px;
        }
        .titan-chip {
          margin-top: 4px;
        }
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        @include respond-down(sm) {
          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}
