.authenticate {
  position: relative;
  min-height: 100vh;
  &__logo-mobile {
    display: none;
    @include respond-down(sm) {
      display: block;
      position: absolute;
      top: 16px;
      left: 16px;
      width: auto;
      height: 32px;
      object-fit: cover;
    }
  }
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    @include respond-down(sm) {
      display: none;
    }
    &--mobile {
      display: none;
      @include respond-down(sm) {
        display: block;
        width: 100vw;
        height: auto;
        object-fit: cover;
      }
    }
  }
  &__page {
    padding: 100px;
    display: flex;
    align-items: center;
    height: 100vh;
    overflow-y: auto;
    @include customScrollTrack(8px, color(background-secondary), 4px);
    @include respond-down(md) {
      justify-content: center;
    }
    @include respond-down(sm) {
      padding: 0;
      align-items: flex-start;
      height: auto;
      min-height: calc(100vh - 320px);
      background-color: color(background-primary);
    }
  }
  &__form {
    width: 502px;
    padding: 40px 24px;
    background-color: color(background-primary);
    border-radius: 8px;
    @include respond-down(sm) {
      height: 100%;
      width: 100%;
    }
    &__top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: auto;
        height: 30px;
        object-fit: cover;
      }
    }
  }
  &__title {
    margin-top: 24px;
    font-weight: 700;
    font-size: 20px;
    color: white;
    margin-bottom: 32px;
  }
}
