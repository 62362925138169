.bot-detail {
  &__box {
    padding: 24px;
    border-radius: 8px;
    background-color: color(background-primary);
    @include respond-down(md) {
      padding: 16px;
    }
    @include respond-down(sm) {
      padding: 16px;
    }
    .top {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      @include respond-down(md) {
        flex-wrap: wrap;
      }
      @include respond-down(sm) {
        flex-wrap: wrap;
        margin-bottom: 16px;
      }
      .info-left {
        display: flex;
        align-items: center;
        @include respond-down(md) {
          margin-bottom: 24px;
          width: 100%;
        }
        @include respond-down(sm) {
          margin-bottom: 0;
          width: 100%;
        }
        .info-title {
          display: flex;
          align-items: center;
          @include respond-down(sm) {
            width: 100%;
          }
          .img-bot {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 12px;
          }
          .name {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: white;
          }
        }
      }
      .info-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        width: 100%;
        flex: 1;
        @include respond-down(md) {
          flex-direction: row-reverse;
          margin-bottom: 16px;
        }

        @include respond-down(sm) {
          margin-top: 16px;
          flex-direction: row-reverse;
          margin-bottom: 16px;
        }
        .user {
          margin-left: 16px;
          padding: 4px 8px;
          border-radius: 1000px;
          border: 1px solid #1e7eff;
          color: #1e7eff;
          @include respond-down(md) {
            margin-bottom: 0;
            margin-right: auto;
            margin-left: 0;
          }

          @include respond-down(sm) {
            margin-bottom: 0;
            margin-right: auto;
            margin-left: 0;
          }
          &.full {
            border: 1px solid #ff392c;
            color: #ff392c;
          }
        }
        .exchange {
          display: flex;
          align-items: center;
          margin-left: 12px;
          @include respond-down(sm) {
            // display: none;
            white-space: nowrap;
          }
          img {
            margin-right: 8px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            padding: 4px;
            background-color: color(background-secondary-bold);
          }
          &__name {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
          }
        }
      }
      .exchange-mobile {
        display: none;
        @include respond-down(sm) {
          display: flex;
          margin-left: 0;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        @include respond-down(md) {
          margin-left: 0;
          width: 100%;
        }
        @include respond-down(sm) {
          display: flex;
          width: 100%;
        }
        .btn {
          margin-left: 16px;
          @include respond-down(md) {
            &:first-child {
              margin-left: 0;
            }
          }
          @include respond-down(sm) {
            width: 100%;
            &:first-child {
              margin-right: 8px;
              margin-left: 0;
            }
            &:last-child {
              margin-left: 8px;
            }
          }
        }
      }
    }
    .list-info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-left: -6px;
      margin-right: -6px;
      margin-top: 24px;
      @include respond-down(md) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: initial;
        grid-auto-rows: 1fr;
        grid-row-gap: 12px;
        margin-bottom: 12px;
      }
      @include respond-down(sm) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: initial;
        grid-auto-rows: 1fr;
        grid-row-gap: 12px;
        margin-bottom: 12px;
      }
      .info-item {
        width: calc(100% / 4);
        padding-left: 6px;
        padding-right: 6px;
        margin-bottom: 12px;
        @include respond-down(md) {
          width: 100%;
          margin-bottom: 0;
        }
        @include respond-down(sm) {
          width: 100%;
          margin-bottom: 0;
        }
        &__detail {
          padding: 8px 12px;
          background-color: color(background-third);
          border-radius: 8px;
          @include respond-down(sm) {
            height: 100%;
          }
          .title {
            font-size: 14px;
            color: color(text-grey);
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 4px;
            svg {
              margin-left: 8px;
            }
          }
          .value {
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            color: color(text-default);
            &.success {
              color: color(green-primary);
            }
            &.error {
              color: color(error-primary);
            }
          }
          &__tooltip {
            margin-left: 4px;
          }
        }
      }
    }
    .box-price {
      background-color: color(background-third);
      padding: 12px;
      border-radius: 8px;
      .title {
        font-size: 14px;
        color: color(text-grey);
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 4px;
      }
      .description {
        font-size: 16px;
        color: color(text-default);
        line-height: 24px;
      }
    }
    .tabs {
      margin-top: 24px;
    }
  }
  &__tab-overview {
    .box-statistic {
      display: flex;
      flex-wrap: wrap;
      padding: 12px;
      background-color: color(background-third);
      border-radius: 8px;
      margin-left: -6px;
      margin-right: -6px;
      @include respond-down(sm) {
        padding: 12px 6px 0 6px;
      }
      &__item {
        width: calc(100% / 4);
        padding-left: 6px;
        padding-right: 6px;
        @include respond-down(md) {
          width: calc(100% / 2);
          margin-bottom: 12px;
        }
        @include respond-down(sm) {
          width: calc(100% / 2);
          margin-bottom: 12px;
        }
        .detail {
          padding: 32px 24px 16px 24px;
          background-color: color(background-primary);
          border-radius: 8px;
          @include respond-down(md) {
            padding: 16px;
          }
          @include respond-down(sm) {
            padding: 16px;
          }
          .icon {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: color(background-secondary-bold);
          }
          .title {
            margin-top: 12px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: color(text-grey);
          }
          .value {
            margin-top: 4px;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #ffffff;
          }
        }
      }
    }
  }
  &__chart {
    margin-top: 24px;
    .top {
      display: flex;
      justify-content: space-between;
      .left {
        padding-left: 52px;
        @include respond-down(sm) {
          padding-left: 0;
        }
        .title {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          color: color(text-grey);
        }
        .roi {
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          color: white;
          &.success {
            color: color(green-primary);
          }
          &.error {
            color: color(error-primary);
          }
        }
      }
      .titan-select {
        width: 240px;
      }
    }
  }
}
